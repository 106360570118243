import {
    Component,
    AnalyticsEvents,
    ComponentOptions,
    IComponentBindings,
    IChangeAnalyticsCustomDataEventArgs,
    InitializationEvents,
    Initialization,
    IStringMap,
} from 'coveo-search-ui';

import { ISitecoreContextInitializationEventArgs, SitecoreContextEvents } from '../../events/ContextEvents';

export interface ICoveoForSitecoreAnalyticsOptions {
    scAnalyticsCustomMetadata: IStringMap<string>;
    scOriginContext: string;
    scOverrideOriginWithPageName: boolean;
    scOverrideCurrentLanguage: boolean;
    endpoint: string;
}

export class CoveoForSitecoreAnalytics extends Component {
    static ID = 'ForSitecoreAnalytics';

    static options: ICoveoForSitecoreAnalyticsOptions = {
        scAnalyticsCustomMetadata: ComponentOptions.buildJsonOption(),
        scOriginContext: ComponentOptions.buildStringOption({
            defaultValue: 'WebsiteSearch',
        }),
        scOverrideOriginWithPageName: ComponentOptions.buildBooleanOption({
            defaultValue: false,
        }),
        scOverrideCurrentLanguage: ComponentOptions.buildBooleanOption({
            defaultValue: true,
        }),
        endpoint: ComponentOptions.buildStringOption(),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreAnalyticsOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreAnalytics.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreAnalytics, options);

        this.bind.onRootElement(InitializationEvents.afterInitialization, () => this.onAfterInitialization());
        this.bind.onRootElement<ISitecoreContextInitializationEventArgs>(
            SitecoreContextEvents.onSitecoreContextInitialization,
            (args) => this.onSitecoreContextInitialization(args)
        );
    }

    private onAfterInitialization(): void {
        if (this.bindings.usageAnalytics && this.options.scOriginContext) {
            this.bindings.usageAnalytics.setOriginContext(this.options.scOriginContext);
        }
    }

    private onSitecoreContextInitialization(sitecoreContext: ISitecoreContextInitializationEventArgs): void {
        this.completeAnalyticsCustomDataWithSitecoreContext(sitecoreContext);
        this.setUsageAnalyticsEnpointServiceUrl(sitecoreContext);
    }

    private completeAnalyticsCustomDataWithSitecoreContext(
        sitecoreContext: ISitecoreContextInitializationEventArgs
    ): void {
        this.bind.onRootElement<IChangeAnalyticsCustomDataEventArgs>(
            AnalyticsEvents.changeAnalyticsCustomData,
            (args: IChangeAnalyticsCustomDataEventArgs) => {
                const options = this.options;
                if (options) {
                    if (options.scOverrideCurrentLanguage && !!sitecoreContext.currentLanguage) {
                        args.language = sitecoreContext.currentLanguage;
                    }
                    if (options.scOverrideOriginWithPageName && !!sitecoreContext.sitecorePageName) {
                        args.originLevel1 = sitecoreContext.sitecorePageName;
                    }

                    const customMetadata = {
                        pageFullPath: sitecoreContext.sitecorePageFullPath,
                        sitename: sitecoreContext.siteName, // Kept for backward-compatibility with legacy components
                        siteName: sitecoreContext.siteName,
                    };

                    const userContext = window['CoveoForSitecoreUserContext'];
                    if (!!userContext && !!userContext.handler) {
                        const context = userContext.handler.getContext();
                        if (!!context) {
                            for (const key in context) {
                                _.extend(customMetadata, {
                                    ['context_' + key]: context[key],
                                });
                            }
                        }
                    }

                    if (options.scAnalyticsCustomMetadata) {
                        _.extend(customMetadata, options.scAnalyticsCustomMetadata);
                    }

                    _.extend(args.metaObject, customMetadata);
                }
            }
        );
    }

    private setUsageAnalyticsEnpointServiceUrl(sitecoreContext: ISitecoreContextInitializationEventArgs): void {
        if (
            this.bindings.usageAnalytics &&
            this.bindings.usageAnalytics.endpoint &&
            this.bindings.usageAnalytics.endpoint.options
        ) {
            this.bindings.usageAnalytics.endpoint.options.serviceUrl =
                this.options.endpoint || `${sitecoreContext.restEndpointUri}/ua`;
        }
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreAnalytics);
